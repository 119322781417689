import React, { useState } from 'react'
import { Link } from 'gatsby'

import { CartContext } from '../CartProvider'
import Plus from '../../../images/svgs/plus.svg'
import { S_IFREG } from 'constants'


const Ingredient: React.FC = (data) => {
  const { ingredient, className, showFeatured } = data;
  const [fade, setFade] = useState(false)
  const featured = showFeatured && ingredient.featured
  const featuredRecipe = ingredient.featuredRecipes && ingredient.featuredRecipes[0]

  const sizeClass = typeof className != "undefined" ? className : `${featured ? 'col-12 col-sm-6' : 'col-6  col-sm-3'}`

  return (
    <CartContext.Consumer>
      {(cart) => {
        const count = cart.itemCount(ingredient)

        return (
          <div className={sizeClass}>
            <div className={`${featured ? 'position-relative bg-info-secondary' : ''} d-flex flex-column d-md-block`}>
              <div className={`position-relative hover-container d-inline-block px-0 ${featured ? 'col-12 col-md-6' : 'col-12'}`}>
                <div className="bg-info-secondary h-150px h-md-250px d-flex justify-content-center">
                  <img
                    className="img-fluid mb-auto mt-auto mh-75 mw-90"
                    src={ingredient.picture.file.url}
                    alt={ingredient.picture.description}
                  />
                </div>
              </div>
              {featured && <div className="col-12 col-md-6 d-inline-flex flex-column position-md-absolute h-100 justify-content-center text-md-center pl-md-0">
                <p className="small" dangerouslySetInnerHTML={{ __html: ingredient.shortDescription.replace(/\*([^*]*)\*/g, '<b>$1</b>') }} />
                {featuredRecipe &&
                  <p className="small">Try it in our <a className="font-weight-bold" href={`/recipes/${featuredRecipe.slug}`}>{featuredRecipe.name}</a></p>
                }
              </div>
              }
            </div>
            <div className="row">
              <div className="col-9 pr-1">
                <Link to={`/products/${ingredient.slug}`} className="text-body">
                  <h5 className="font-weight-bold pt-2 mb-1 smaller">{ingredient.name}</h5>
                </Link>
                <p className="small mb-1 smaller">{ingredient.brand}</p>
              </div>
              <div className="col-3 px-0 pr-4 plus add-to-cart text-right pt-2">
                <Plus className={`img-fluid fill-primary w35p cursor-pointer add-to-cart ${fade ? 'fade' : ''}`} onClick={() => {
                  setFade(true)
                  cart.add(ingredient.sku)
                }}
                  onAnimationEnd={() => setFade(false)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <p className="small smaller">${(ingredient.priceCents / 100).toFixed(2)}{ingredient.quantityDescription &&
                  ` - ${ingredient.quantityDescription}`}</p>
              </div>
              <div className="col-4 small smaller pr-4 pl-0 text-right">
                {count} in cart
              </div>
            </div>
          </div >
        )
      }}
    </CartContext.Consumer>
  )
}

export default Ingredient
