import React from 'react'

const RecipeCard: React.FC = (data) => {
  const { recipe, selectRecipe, selected } = data

  return (
    <div className={`cursor-pointer py-2 px-2`} key={recipe.slug} onClick={() => selectRecipe(recipe)}>
      <div className={`selector text-center border hover-border p-3 mb-2 ${selected ? 'bg-dark' : ''}`}>
        <img className="img-fluid" src={`${recipe.rectangularImage?.fluid?.src}`} alt="A recipe" />
        <div className="d-flex flex-column justify-content-center h-50p">
          <h5 className="font-weight-bold text-body pt-2 small limit-lines-two mb-0">{recipe.name}</h5>
        </div>
      </div>
      { recipe.shortDescription && <div className="small smaller d-none d-sm-block" dangerouslySetInnerHTML={{ __html: recipe.shortDescription?.childMarkdownRemark?.html }}></div>}
      { recipe.time && <p className="smaller mb-0">Time: {recipe.time} Minutes</p>}
      { recipe.servings && <p className="smaller mb-0">Servings: {recipe.servings}</p>}
    </div >
  )
}

export default RecipeCard;
