import React from 'react'

import { CartContext } from '../../shared/CartProvider'
import RecipeCard from '../../shared/RecipeCard'
import Ingredient from '../../shared/Ingredient';

const YouMightlike: React.FC = (data) => {
  const { sectionIngredients, recipes } = data;

  return (
    <CartContext.Consumer>
      {(cart) => {
        const sortedRecipes = matchAndSortRecipes(cart.contents, recipes);

        const { recomendedIngredients, recomendedRecipe } = findRecomendedRecipeAndIngredients(sectionIngredients, sortedRecipes, cart.contents)

        if (recomendedIngredients) {
          return (
            <div className="row py-2 my-2 bg-brand-dark">
              <div className="col-12">
                <h2>You might like</h2>
              </div>
              <div className="col-6 col-sm-9">
                <div className="row">
                  {recomendedIngredients.map((recomendedIngredient) => (
                    <div className="col-sm-4">
                      <Ingredient ingredient={recomendedIngredient} className="" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-6 col-sm-3">
                Makes
                <RecipeCard recipe={recomendedRecipe} className="" />
              </div>
            </div>
          )
        } else {
          return ""
        }
      }}
    </CartContext.Consumer>
  )
}

const findRecomendedRecipeAndIngredients = (sectionIngredients, sortedRecipes, cartContents) => {
  const recipe = sortedRecipes.find((recipe) => {
    return findRecomendedIngredients(sectionIngredients, recipe, cartContents).length > 0;
  })

  if (recipe) {
    const ingredients = findRecomendedIngredients(sectionIngredients, recipe, cartContents);

    return {
      recomendedRecipe: recipe,
      recomendedIngredients: ingredients.slice(0, 3)
    }
  } else {
    return {
      recomendedRecipe: null,
      recomendedIngredients: null
    }
  }
}

const matchAndSortRecipes = (cartContents, recipes) => {
  const scoredRecipes = recipes.map((recipe) => {
    return {
      recipe: recipe,
      score: scoreRecipe(cartContents, recipe)
    };
  });

  return scoredRecipes.sort((a, b) => {
    return b.score - a.score;
  }).map((recipeData) => {
    return recipeData.recipe;
  })
}

const scoreRecipe = (cartContents, recipe) => {
  if (typeof recipe.ingredients === 'undefined') {
    return 0;
  }


  return (recipe.ingredients || []).reduce((accumulator, ingredient) => {
    const cartElement = findInCart(cartContents, ingredient)

    if (cartElement) {
      return accumulator + 1;
    } else {
      return accumulator;
    }
  }, 0);
}

const findRecomendedIngredients = (ingredients, recipe, cartContents) => {
  return ingredients.filter((ingredient) => {
    return recipe.ingredients &&
      recipe.ingredients.find((recipeIngredient) => {
        return recipeIngredient?.ingredient?.sku == ingredient.sku
      })
      && findInCart(cartContents, { ingredient: ingredient }) == undefined
  })
}

const findInCart = (cartContents, ingredient) => {
  return cartContents.find((item) => {
    return item[0] == ingredient?.ingredient?.sku;
  })
}

export default YouMightlike;
