import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import IndexLayout from '../layouts'
import YouMightlike from '../components/shopFlow/YouMightLike'
import Ingredient from '../components/shared/Ingredient'

import './index.scss'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
        query AllIngredientsWithCategory {
          allContentfulSellableIngredient {
            nodes {
              id
              name
              sku
              picture {
                file {
                  url
                }
              }
              section
              priceCents
            }
          }
          allContentfulRecipe {
            nodes {
              name
              slug
              mainImage {
                file {
                  url
                }
              }
              ingredients {
                ingredient {
                  id
                  sku
                }
              }
            }
          }
        }
      `}
    render={(data) => {
      const [currentSection, setCurrentSection] = useState(0);

      const sections = ['Staples', 'Vegetables', 'Proteins', 'Oils And Sauces', 'Flavors', 'Add Ons'];
      const ingredients = data.allContentfulSellableIngredient.nodes.filter((ingredient) => {
        return ingredient.picture != null && ingredient.section == sections[currentSection];
      });
      const recipes = data.allContentfulRecipe.nodes;

      return (
        <IndexLayout>
          <section className="container">
            <div className="row py-5">
              <h1 className="mx-auto display-2">{sections[currentSection]}</h1>
            </div>
            <div className="row pt-1 pb-4">
              <div className="col-6">
                {currentSection > 0 &&
                  <a className="cursor-pointer" onClick={() => {
                    setCurrentSection(currentSection - 1)
                  }} ><h3>Previous</h3></a>
                }
              </div>
              <div className="col-6 d-flex flex-row-reverse">
                {currentSection < 5 &&
                  <a className="cursor-pointer" onClick={() => {
                    setCurrentSection(currentSection + 1)
                  }} ><h3>Next</h3></a>
                }
              </div>
            </div>
            <div className="row">
              {sections.map((section, index) => {
                const extraClass = index === currentSection ? "font-weight-bold" : "cursor-pointer";

                return (
                  <div className={`col-2 ${extraClass}`} onClick={() => {
                    setCurrentSection(index);
                  }} >
                    {section}
                  </div>
                )
              })}
            </div>
            <YouMightlike sectionIngredients={ingredients} recipes={recipes} />
            <div className="row py-4">
              {ingredients.map((ingredient, index) => {
                return <Ingredient ingredient={ingredient} index={index} />
              })}
            </div>
          </section>
        </IndexLayout >
      );
    }}
  />
)

export default IndexPage
